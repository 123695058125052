$color-primary: rgb(240, 85, 150);
$color-text: hsl(0, 0%, 20%);
$color-subtext: hsl(0, 0%, 30%);
$color-border: hsl(0, 0%, 85%);
$color-box-background: mix($color-primary, white, 8%);
$link-background: mix($color-primary, white, 16%);
$border-radius: 4px;
$font-family: Bitter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

body {
  box-sizing: content-box;
  font-family: $font-family;
  line-height: 1.7;
  overflow-x: hidden;
  color: $color-text;
  font-size: 1rem;

  @media (min-width: 820px) {
    font-size: 1.2rem;
  }
}

.wrapper {
  margin: 0 auto;
  padding: 0 3em;
  max-width: 56em;
}

main {
  margin: 3em 0 2em;
}

time {
  display: block;
  line-height: 1.5;
  color: $color-subtext;
  font-size: 0.9em;
  margin-top: 0.25em;
  &:last-of-type{
    margin: 0 0 1em;
  }
}

footer {
  margin: 0 0 2em;
  font-size: 0.8em;
  color: mix($color-text, white, 80%);
  padding-top: 1em;
}

#footer-links {
  display: flex;
  justify-content: space-between;
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  max-height: 75vh;
  border-radius: $border-radius;
}

blockquote {
  padding: 1.5em;
  margin: 0;
  font-size: 0.88em;
  background: $color-box-background;
  border-radius: $border-radius;

  p {
    margin: 0;
  }
}

hr {
  width: 100%;
  border: 0;
  height: 1px;
  margin: 1.5em 0;
  background: $color-border;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  margin-bottom: 0;
  padding-bottom: 0;
}

.subhead {
  font-family: "Lato";
  margin: 0;
}

a {
  transition: all .1s ease-in;
  box-shadow: inset 0 -7px 0 $link-background;
  padding: 0 0.1em;
  text-decoration: none;
  border-bottom: 1px solid $color-border;
  border-radius: 2px;
  color: $color-primary;
  &:hover {
    background: $link-background;
    box-shadow: none;
  }
  &:after {
    position: relative;
    top: -0.5em;
    font-size: 0.7em;
    content: "↗";
    color: #aaaaaa;
  }
  &.internal-link:after,
  &.footnote:after,
  &.reversefootnote:after {
    content: "";
  }
}

a:has(code){
  box-shadow: none;
}

*:focus {
  background: #ffe8bc !important;
  color: black !important;
}

nav {
  padding: 4vh 0;
  background: url(assets/background.svg);
}

#notes-entry-container {
  display: grid;
  grid-gap: 2em;
  grid-template-areas:
    "content"
    "side";

  @media (min-width: 700px) {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "content side";
  }
}

.backlink-box {
  background: $color-box-background;
  padding: 1em;
  border-radius: $border-radius;
}

code {
  background: #f5f5f5;
  padding: 0.1em 0.2em;
  border-radius: 4px;
}

.footnotes {
  font-size: 0.8em;
  margin-top: 2.5em;
  padding-top: 0.7em;
  border-top: 1px solid #d9d9d9;
}

.invalid-link {
  color: #444444;
  cursor: help;
  background: #fafafa;
  padding: 0 0.1em;
}

.invalid-link-brackets {
  color: #ccc;
  cursor: help;
}

ol {
  padding-inline-start: 16px;
  line-height: 1.5;
}

/* Category Grid */
#category-grid {
  display: flex;
  gap: 20px;
  justify-content: space-between;

  .menu {
    max-width: 25%;
  
    h2 {
      margin-top: 1.5em;
      line-height: .75;
      font-size: 2em;
      box-shadow: inset 0 -10px 0 $link-background;
    }

    .post-list {
      font-size: 0.9em;
    }
  }

  .grid-container {
    width: 75%;
    aspect-ratio: 1/1;
    margin: 0;
    padding: 10px;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .grid-item {
    background-color: $color-box-background;
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    border-bottom: 6px solid $link-background;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: 0px 1px $color-border;

    p {
      font-family: Lato;
      margin-top: 0;
    }
  }

  .one {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .two {
    grid-column: 3/4;
    grid-row: 1/3;
  }

  .three {
    grid-column: 2/4;
    grid-row: 3/4;
  }

  .four {
    grid-column: 1/2;
    grid-row: 2/4;
  }

  .five {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}

@media only screen and (max-width: 800px) {
  #category-grid {
    flex-direction: column;

    .grid-container {
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
}